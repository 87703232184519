<template>
  <div class="add-question">
    <!-- 头部标题 -->
    <pageTitle :pageTitle="`${title}问卷`">
      <div class="headBtnGroup">
        <vh-button type="text" @click="openSet" class="name_set_button">
          修改「问卷」显示名称
        </vh-button>
        <i class="iconfont-v3 saasicon_help_m" @click="showPreview"></i>
      </div>
    </pageTitle>
    <!-- 问卷组件 -->
    <question ref="questions" :alias="alias"></question>

    <!-- 别名设置弹框 -->
    <VhallDialog
      title="提示"
      :visible.sync="dialogNameSet"
      :close-on-click-modal="false"
      class="zdy-async-dialog"
      width="400px"
    >
      <div class="async__body">
        <div class="async__ctx">
          <vh-input
            v-model="alias"
            :placeholder="'请输入名称'"
            maxlength="8"
            v-clearEmoij
            show-word-limit
          ></vh-input>
          <p class="setname_tip">
            针对直播中推送场景，可将名称修改为「投票」「报名」等，修改后用户观看直播时看到的是修改后的名称。
          </p>
        </div>
        <div class="async__footer">
          <vh-button type="info" plain size="medium" @click="saveNewName('cancel')" round>
            取消
          </vh-button>
          <vh-button type="primary" size="medium" @click="saveNewName" round>保存</vh-button>
        </div>
      </div>
    </VhallDialog>
    <!-- 图列展示弹框 -->
    <VhallDialog
      :visible.sync="dialogPreview"
      :modalClick="true"
      :show-close="true"
      class="zdy-dialog"
      width="740px"
    >
      <div class="img_base">
        <img src="../images/Q_preview.png" alt="" />
      </div>
    </VhallDialog>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import question from '@/components/Question/question';
  export default {
    data() {
      return {
        alias: '',
        dialogNameSet: false,
        dialogPreview: false
      };
    },
    components: {
      PageTitle,
      question
    },
    computed: {
      title() {
        if (this.$route.query.questionId) {
          return '编辑';
        } else {
          return '创建';
        }
      }
    },
    methods: {
      showPreview() {
        this.dialogPreview = true;
        console.log('%c 展示例图', 'color:blue');
      },
      openSet() {
        this.dialogNameSet = true;
        this.alias = this.alias || this.$route.query.alias || '问卷';
        console.log('%c 打开别名设置弹框', 'color:blue');
      },
      saveNewName(data) {
        this.dialogNameSet = false;
        if (data == 'cancel') {
          this.alias = this.$route.query.alias || '问卷';
        }
        console.log(this.$route.query);
      }
    }
  };
</script>

<style lang="less" scoped>
  #settingBox ::v-deep .control-area {
    width: 185px;
    .categroys {
      border-bottom: 1px dashed #fb3a32;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  #settingBox ::v-deep .question-wrap.click {
    border: 1px solid #fb3a32;
  }
  #settingBox ::v-deep .show-text:hover {
    border-color: #fb3a32;
  }

  .headBtnGroup {
    float: right;
    .name_set_button {
      margin-right: 8px;
      color: #3562fa;
    }
  }
</style>

<style lang="less">
  .add-question .zdy-dialog {
    .img_base {
      padding: 4px;
      border-radius: 4px;
      background: #000;
      img {
        width: 100%;
      }
    }
  }
  .setname_tip {
    margin-top: 10px;
    color: #666 !important;
  }
</style>
